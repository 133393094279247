<template>
  <main>
    <Loading v-if="isLoading"></Loading>
    <div v-else>
      <h1 class="text-2xl font-bold">Verification Portal</h1>
      <div class="flex justify-end items-end">
        <button
          class="bg-gray-800 py-3 px-6 rounded-md text-white"
          @click="
            $router.push({
              name: 'ViewDocumentVerification',
              params: {
                id: merchant_id,
              },
            })
          "
        >
          &laquo; Back
        </button>
      </div>
      <div class="grid grid-cols-1 gap-3 mt-5">
        <div
          class="flex justify-center flex-col p-5 shadow-lg border-gray-200 border-2 rounded-md"
        >
          <h4 class="text-xl font-bold">Add reason for rejection</h4>
          <div class="grid grid-cols-1 gap-4 mt-5">
            <form @submit.prevent="disapproveKYC">
              <div class="grid grid-cols-1 gap-5 mt-10">
                <div class="flex flex-col pt-4">
                  <textarea
                    required
                    v-model="reject_reason"
                    placeholder="Type reason here..."
                    class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
                  ></textarea>
                </div>
              </div>
              <button
                class="inline-block uppercase shadow mt-10 mx-3 leading-6 hover:bg-red-300 focus:shadow-outline focus:outline-none text-blue text-xs py-2 px-8 rounded"
                @click="
                  $router.push({
                    name: 'ViewDocumentVerification',
                    params: {
                      id: merchant_id,
                    },
                  })
                "
              >
                Cancel
              </button>
              <button
                type="submit"
                class="inline-block uppercase shadow bg-blue-600 mt-10 leading-6 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
              >
                Done
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import Loading from "./general-components/Loading.vue";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      merchant_id: this.$route ? this.$route.params.id : null,
      reject_reason: "",

      payload: {
        //_id: "",
        businessId: "",
        businessName: "",
        Status: "",
        comment: "",
      },
    };
  },
  components: {
    Loading,
  },
  methods: {
    ...mapActions(["DISAPPROVE_KYC"]),
    async disapproveKYC() {
      this.isLoading = true;
      // this.payload = this.$route.params.id;
      this.payload.businessId = this.$route ? this.$route.params.id : null;
      this.payload.comment = this.reject_reason;
      let res = await this.$store.dispatch("DISAPPROVE_KYC", this.payload);

      if (res.status) {
        this.isLoading = false;
        alert("Success!");
        this.$router.go(-1);
      } else {
        alert(res.error);
      }
    },
  },
};
</script>

<style></style>
